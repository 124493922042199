import client from './client';

class PricesService {

    fetchPrices(params) {
        return client.get('prices', params);
    }

    fetchPriceCount(params) {
        return client.get('prices/count', params);
    }

    reassignPrice(priceId, newArticleId) {
        return client.put('prices/' + priceId + '/reassign', {
            articleId: newArticleId
        });
    }

    reassignMultiplePrices(pricesArray, newArticleId) {
        return client.put('prices/reassignMany', {
            prices: pricesArray,
            articleId: newArticleId
        });
    }

    reassignSupplierArticle(priceId, newArticleId) {
        return client.put('prices/' + priceId + '/reAssignSupplierArticle', {
            articleId: newArticleId
        });
    }

    invalidatePrice(priceId) {
        return client.put('prices/' + priceId + '/invalidate');
    }

    deletePrice(priceId) {
        return client.delete('prices/' + priceId);
    }


    deleteMultiplePrices(pricesArray) {
        return client.put('prices/deleteMany', {
            prices: pricesArray
        });
    }

    deAssignMultiplePrices(pricesArray) {
        return client.put('prices/deAssignMany', {
            prices: pricesArray
        });
    }

    acceptProposals(pricesArray) {
        return client.put('prices/acceptProposals', {
            prices: pricesArray
        })
    }

    acceptSecondaryProposals(pricesArray) {
        return client.put('prices/acceptSecondaryProposals', {
            prices: pricesArray
        })
    }

    rejectProposedArticles(pricesArray) {
        return client.put('prices/rejectProposals', {
            prices: pricesArray
        })
    }

    rejectMatchedArticles(pricesArray) {
        return client.put('prices/rejectMatchedArticles', {
            prices: pricesArray
        })
    }

    createPermanentRejection(priceId) {
        return client.put('prices/' + priceId + '/permanentReject');

    }

}

export default new PricesService();